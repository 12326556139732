import React from 'react';

import styled from 'styled-components';
import { Heading } from '../sections/Home/components/styles';
import RootLayout from '../layouts/RootLayout';

const Container = styled.section`
    max-width: 1280px;
    padding: 0 20px;
    margin: 100px auto 0;

    @media screen and (max-width: 500px) {
        margin: 50px auto 0;
    }
`;

const SectionHead = styled.div``;
const SectionBody = styled.div`
    margin-top: 32px;
    padding-bottom: 64px;
`;

const SubHead = styled.h3`
    font-size: 18px;
    font-weight: 500;
    margin-top: 16px;
`;

const Ul = styled.ul`
    margin-top: 16px;
    padding-left: 40px;
`;
const Item = styled.li`
    margin-bottom: 16px;
`;

const SubText = styled.p`
    margin-top: 16px;

    a {
        color: #2ca8de;
    }
`;

export default function TermsOfUse() {
    return (
        <RootLayout>
            <Container>
                <SectionHead>
                    <Heading secondary center>
                        <span>Authentic</span>ate.com
                    </Heading>
                    <Heading secondary center margin="16px">
                        Terms & Conditions Of Use
                    </Heading>
                </SectionHead>
                <SectionBody>
                    <SubText margin="16px">
                        Authenticating.com LLC (“Authenticating.com” or “we”) provides this website
                        (the “Site”) and the services made available through the site (the
                        “Services”) to you, subject to these terms of use (“Terms”). The Terms
                        constitute a legal agreement between you and Authenticating.com and govern
                        all use of this Site, including all content, information, and services
                        provided on the Site. For the avoidance of doubt, your access to and use of
                        the Site may also be governed by (and subject to) the terms and conditions
                        of your (or your company’s) agreement with Authenticating.com.
                    </SubText>
                    <SubText margin="16px">
                        By accessing, browsing, or using this Site, you acknowledge that you have
                        read, understood, and agree to be bound by these Terms. We may update these
                        Terms at any time, with or without notice to you. Each time you access this
                        Site, you agree to be bound by the Terms then in effect. If you do not agree
                        to these terms, you are not granted permission to use the Site and must exit
                        immediately.
                    </SubText>
                    <SubHead>Privacy</SubHead>
                    <SubText margin="16px">
                        Please review our Privacy Policy, which is incorporated into these Terms by
                        reference as if fully set forth herein, for a description of our privacy
                        practices and an explanation of how we may use any personal information we
                        collect on this Site.
                    </SubText>
                    <SubHead>Eligibility for Use</SubHead>
                    <SubText margin="16px">
                        You represent and warrant that you are at least 18 years of age, of legal
                        competence to enter into this agreement, and are not a person barred from
                        receiving services under the laws of the United States or other applicable
                        jurisdiction. We reserve the right to deny, in our sole discretion, any
                        access or use of the Services without notice for any or no reason.
                    </SubText>
                    <SubHead>Patents, Copyrights and Trademarks</SubHead>
                    <SubText margin="16px">
                        The technology and content provided on this Site are owned by or licensed to
                        Authenticating.com and protected by United States and international
                        intellectual property laws. Authenticating.com and its licensors retain all
                        proprietary rights to that content and technology. Please see notices on
                        individual referenced pages, products and services for additional notices
                        and information. The trademarks, service marks, trade names and logos
                        appearing on this Site are trademarks of Authenticating.com and or its
                        affiliates or licensors. All rights are reserved.
                    </SubText>
                    <SubHead>License</SubHead>
                    <SubText margin="16px">
                        By offering this Site for use, we provide you with a worldwide,
                        nonexclusive, nontransferable, revocable, limited license to access and use
                        of the information and services on this Site, strictly subject to and in
                        accordance with these Terms. We reserve the right to terminate this license
                        at any time and for any reason. Your failure to comply with these Terms will
                        result in automatic termination of this license, with or without prior
                        notice, and you must immediately destroy all copies of downloaded materials
                        in your possession or control. Except for the limited license set forth in
                        these Terms, Authenticating.com does not grant you any express or implied
                        rights or licenses under any patents, trademarks, copyrights, or other
                        proprietary or intellectual property rights.
                    </SubText>
                    <SubHead>Usage Restrictions</SubHead>
                    <SubText margin="16px">
                        By accessing, browsing, or using this Site, you agree to the following
                        restrictions:
                    </SubText>
                    <Ul>
                        <Item>
                            you will not use, intentionally or unintentionally any of the content,
                            information, or services on this Site in a manner contrary to or in
                            violation of any applicable international, national, federal, state, or
                            local law, rule, or regulation having the force of law. Please note that
                            Authenticating.com is not a consumer reporting agency, and you will not
                            use any of the content, information, or services on this Site as a
                            factor in establishing a consumer's eligibility for credit or insurance
                            to be used primarily for personal, family, or household purposes, for
                            employment purposes, or for any other purpose authorized under section
                            1681b of the Fair Credit Reporting Act (15 U.S.C.A. § 1681(b))
                        </Item>
                        <Item>
                            you will not use this Site in any manner that could harm, infect, take
                            over, disable, overburden, or otherwise impair any of our computer
                            systems, including, but not limited to, the servers, networks, and other
                            components connected to or used for this Site;
                        </Item>
                        <Item>
                            you will not interfere with any other party's use and enjoyment of this
                            Site, or of any of the content, information, or services provided on
                            this Site;
                        </Item>
                        <Item>
                            to the extent that you are able to upload, post or otherwise transmit
                            content using this Site you will not upload, post, or otherwise transmit
                            any content that you do not have a right to transmit under any law or
                            under contractual or fiduciary relationships (such as inside
                            information, proprietary and confidential information learned or
                            disclosed as part of employment relationships or under nondisclosure
                            agreements);
                        </Item>
                        <Item>
                            you will not upload, post, or otherwise transmit any material that
                            contains software viruses or any other computer code, files, or programs
                            designed to interrupt, destroy, or limit the functionality of any
                            computer software or hardware or telecommunications equipment;
                        </Item>
                        <Item>
                            you will not attempt to gain unauthorized access to any services,
                            accounts, computer systems, or networks connected to any server used for
                            this Site, through password mining, hacking, or any other means;
                        </Item>
                        <Item>
                            you will not seek to gain access to any materials or information through
                            any means not intentionally made available by us;
                        </Item>
                        <Item>
                            you will not reproduce, duplicate, copy, download, store, further
                            transmit, disseminate, transfer, or otherwise exploit this Site, or any
                            portion hereof without Authenticating.com's prior written consent,
                            except that you may reproduce limited content from this Site describing
                            Authenticating.com's products and services solely to educate users or
                            potential users about Authenticating.com products or services ;
                        </Item>
                        <Item>
                            you will not use any framing techniques to enclose any portion of this
                            Site (including, but not limited to, images, text, page layout, and
                            form) without Authenticating.com's prior written consent;
                        </Item>
                        <Item>
                            you will not make any use of the trademarks, service marks, trade names,
                            logos, and graphics on this Site without Authenticating.com's prior
                            written consent;
                        </Item>
                        <Item>
                            you will not use any network monitoring or discovery software to
                            determine the Site’s architecture, or extract information about usage,
                            individual identities, or users;
                        </Item>
                        <Item>
                            you will not use any robot, spider, other automatic software or device,
                            or manual process to monitor or copy our Site or the content,
                            information, or services on this Site without Authenticating.com's prior
                            written consent;
                        </Item>
                        <Item>
                            you will not collect any email addresses made available on this Site for
                            purposes of promotions or marketing campaigns;
                        </Item>
                        <Item>
                            you will not distribute, on or through this Site, any advertising,
                            promotion, solicitation for goods, services, or funds, or other
                            solicitation for others to become members of any enterprise or
                            organization without Authenticating.com's prior written permission; and
                        </Item>
                        <Item>
                            you will not use or otherwise export or re-export this Site, or any
                            portion thereof, in violation of the export control laws and regulations
                            of the United States of America or any other country.
                        </Item>
                    </Ul>
                    <SubHead>Disclaimer of Warranties and Limitation of Liability</SubHead>
                    <SubText margin="16px">
                        AUTHENTICATING.COM PROVIDES THIS SITE "AS IS," WITHOUT WARRANTY OF ANY KIND,
                        EXPRESS OR IMPLIED, AND AUTHENTICATING.COM HEREBY DISCLAIMS ALL WARRANTIES
                        OF ALL KINDS, INCLUDING, BUT NOT LIMITED TO:
                    </SubText>
                    <Ul>
                        <Item>
                            WARRANTIES OF PERFORMANCE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
                            PURPOSE, ACCURACY, OMISSIONS, COMPLETENESS, CURRENTNESS, AND DELAYS;
                        </Item>
                        <Item>
                            WARRANTIES THAT ACCESS TO THE SITE AND SERVICES WILL BE UNINTERRUPTED,
                            SECURE, COMPLETE, OR ERROR FREE;
                        </Item>
                        <Item>
                            WARRANTIES AS TO THE LIFE OF ANY URL OR THIRD-PARTY WEB SERVICE; AND
                        </Item>
                        <Item>
                            WARRANTIES WITH REGARD TO ANY CONTENT OR SOFTWARE THAT HAS BEEN MODIFIED
                            IN ANY WAY BY ANYONE OTHER THAN, AND WITHOUT THE EXPRESS APPROVAL OF,
                            AUTHENTICATING.COM.
                        </Item>
                    </Ul>

                    <SubText margin="16px">
                        BY ACCESSING, BROWSING, OR USING THIS SITE, YOU ACKNOWLEDGE THAT:
                    </SubText>
                    <Ul>
                        <Item>
                            PROVISION OF CONTENT AND SOFTWARE ENTAILS THE LIKELIHOOD OF SOME HUMAN
                            AND MACHINE ERRORS, DELAYS, INTERRUPTIONS, AND LOSSES, INCLUDING THE
                            INADVERTENT LOSS OF DATA OR DAMAGE TO MEDIA; AND
                        </Item>
                        <Item>
                            THIS SITE, AND THE PRODUCTS AND SERVICES REFERENCED HEREIN MAY NOT
                            SATISFY YOUR REQUIREMENTS.
                        </Item>
                    </Ul>

                    <SubText margin="16px">
                        IN NO EVENT WILL AUTHENTICATING.COM BE LIABLE, IN CONTRACT, TORT, STRICT
                        LIABILITY, OR OTHERWISE, FOR
                    </SubText>
                    <Ul>
                        <Item>
                            INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY
                            KIND ARISING OUT OF OR IN CONNECTION WITH USE OF THIS SITE, WHETHER OR
                            NOT AUTHENTICATING.COM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                            DAMAGE;
                        </Item>
                        <Item>
                            ANY CLAIM(S) RELATING IN ANY WAY TO YOUR INABILITY OR FAILURE TO PERFORM
                            AUTHENTICATION, RESEARCH OR OTHER WORK OR TO PERFORM SUCH AUTHENTICATION
                            PROPERLY OR COMPLETELY, EVEN IF ASSISTED BY AUTHENTICATING.COM; OR
                        </Item>
                        <Item>
                            ANY CLAIM(S) RELATING IN ANY WAY TO YOUR INABILITY OR FAILURE TO PERFORM
                            AUTHENTICATION, RESEARCH OR OTHER WORK OR TO PERFORM SUCH AUTHENTICATION
                            PROPERLY OR COMPLETELY, EVEN IF ASSISTED BY AUTHENTICATING.COM; OR
                        </Item>
                    </Ul>
                    <SubHead>Consumer Disclosures</SubHead>
                    <SubText margin="16px">
                        Under California Civil Code Section 1789.3, our customers who are residents
                        of California are entitled to the following specific consumer rights
                        information: the Complaint Assistance Unit of the Division of Consumer
                        Service of the Department of Consumer Affairs may be contacted in writing at
                        1020 N. Street, #501, Sacramento, CA, 95814, or by telephone at
                        916.445.1254. The Services are provided by Authenticating.com at the address
                        specified on the Site.
                    </SubText>
                    <SubHead>No Professional Advice</SubHead>
                    <SubText margin="16px">
                        The information and services on this Site are not intended to and shall not
                        be used as legal, financial, or other professional advice. No
                        attorney-client, fiduciary, or other privileged relationship is formed. You
                        use of the content, information, and services on this Site is at your own
                        risk and without recourse to Authenticating.com.
                    </SubText>
                    <SubHead>Advertisers</SubHead>
                    <SubText margin="16px">
                        This Site may contain advertising and sponsorship. Advertisers and sponsors
                        are responsible for ensuring that material submitted for inclusion on this
                        Site is accurate and complies with applicable laws. Authenticating.com is
                        not responsible for the illegality of, or any error or inaccuracy in,
                        advertisers' or sponsors' materials, or for the acts or omissions of such
                        advertisers or sponsors.
                    </SubText>
                    <SubHead>Links to Third Party Websites</SubHead>
                    <SubText margin="16px">
                        This Site may provide links to other third-party websites or online
                        resources. Authenticating.com makes no representations whatsoever about any
                        other website which you may access through this Site. Because
                        Authenticating.com has no control over such websites and resources, you
                        acknowledge and agree that Authenticating.com is not responsible for the
                        availability of such external websites or resources and is not responsible
                        or liable for any content, advertising, products, services or other
                        materials on or available from such websites or resources. It is up to you
                        to take precautions to ensure that whatever you select for your use is free
                        of such items as viruses, worms, trojan horses and other items of a
                        destructive nature. References on this Site to any names, marks, products or
                        services of any third parties or hypertext links to third party websites or
                        information are provided solely as a convenience to you, and do not
                        constitute or imply an endorsement, sponsorship or recommendation of, or
                        affiliation with the third party or its products and services.
                        Authenticating.com makes no representation or warranty as to any third party
                        content, products or services, and you agree that Authenticating.com shall
                        not be responsible or liable, directly or indirectly, for any damage or loss
                        caused or alleged to be caused by or in connection with use of or reliance
                        on any such third party content, products or services available on or
                        through any such website or resource.
                    </SubText>
                    <SubHead>Content</SubHead>
                    <SubText margin="16px">
                        Certain features of the Site (including web forums) may allow you to
                        contribute text, images, data, and other information and materials to the
                        Site for access, use, viewing, and commentary by other users of the Site
                        (collectively, “User Content”). By posting User Content to the Site or
                        otherwise submitting User Content to us, you represent that you have the
                        full legal right to provide the User Content and that use of the User
                        Content by us in connection with the Site, and all other persons and
                        entities, will not: (a) infringe any intellectual property rights of any
                        person or entity or any rights of publicity, personality, or privacy of any
                        person or entity, including as a result of your failure to obtain consent to
                        post personally identifying or otherwise private information about a person;
                        (b) violate any law, statute, ordinance, regulation, or agreement, or
                        promote or provide instructional information about illegal activities,
                        promote physical harm, or injury against any group or individual, or promote
                        any act of cruelty to animals, including instructions on how to assemble
                        bombs, grenades, and other weapons or how to use or obtain illegal drugs;
                        (c) be defamatory, libelous or trade libelous, unlawfully threatening, or
                        unlawfully harassing, or promote discrimination based on race, sex,
                        religion, nationality, disability, sexual orientation, or age; (d)
                        impersonate any person or entity or falsely state or otherwise imply an
                        affiliation with a person or entity, or include any falsified, composite, or
                        otherwise non-authentic depictions of events, locations, landmarks, entities
                        or persons; (e) contain or otherwise transmit any material that contains
                        software viruses or any other computer code, files or programs designed to
                        interrupt, destroy or limit the functionality of any computer software or
                        hardware or telecommunications equipment; (f) be obscene, child
                        pornographic, or indecent; (g) violate any community or Internet standard;
                        (h) constitute misappropriation of any trade secret or know-how; or (i)
                        constitute disclosure of any confidential information owned by any third
                        party. Upon your submission of User Content or other material or information
                        to us, you grant us a worldwide, perpetual, irrevocable, transferable,
                        license to access, use, distribute, reproduce, display, modify, create
                        derivative works based upon, and sublicense the User Content, all without
                        any compensation to you whatsoever.
                    </SubText>
                    <SubText margin="16px">
                        Authenticating.com may, but has no obligation to, from time to time monitor
                        some aspects of the Site, including web forums. Monitored web forums are
                        moderated and messages are screened for inappropriate content. Please be
                        advised that web forum content is the sole responsibility of the person
                        supplying the content, and Authenticating.com assumes no responsibility to
                        control such content. Opinions expressed on Authenticating.com web forums
                        and in any corresponding comments are the personal opinions of the original
                        authors, and not necessarily that of Authenticating.com. All user generated
                        content posted or submitted to a web forum shall be treated as
                        non-confidential information. Web forums may include additional terms, which
                        take precedence over these terms.
                    </SubText>
                    <SubText margin="16px">
                        If you believe that any content or postings on the Site violates your
                        intellectual property or other rights, please notify us with a comprehensive
                        detailed message setting forth the following information: (a) your name and
                        the name of your company, if any; (b) your contact information, including
                        your email address; (c) the nature and substance of your complaint, the
                        specific rights at issue, and your basis for making the complaint, including
                        the content or posting that is objectionable; and (d) the following
                        statement: “The statements, representations, and assertions made in this
                        message are true, complete, and accurate and I have the full legal authority
                        to make each and every such statement, representation, and assertion and to
                        make and be granted any demand made in this message.”
                    </SubText>

                    <SubHead>Registration and Account Information</SubHead>
                    <SubText margin="16px">
                        Certain sections of this Site may require you to register as a user or
                        create an account. By registering or creating an account, you agree to
                        provide accurate and complete information and to inform us of any changes to
                        that information. Each registration is for a single user only, unless
                        otherwise expressly provided on the registration page. You are responsible
                        for maintaining the confidentiality of your account and password and for
                        restricting access to your computer, and you agree to accept responsibility
                        for all activities that occur under your account or password. If you believe
                        there has been unauthorized use, you must notify us immediately.
                    </SubText>
                    <SubHead>Termination</SubHead>
                    <SubText margin="16px">
                        You acknowledge and agree that Authenticating.com, in its sole discretion,
                        may terminate your access to the Site if your conduct is found to be
                        unlawful, inconsistent with or in violation of the letter or spirit of these
                        Terms, or for any other reason. Authenticating.com shall not be liable to
                        you or any third party for termination of access to the Site. Should you
                        object to any terms and conditions of these Terms, or to any subsequent
                        modifications thereto, your only recourse is to immediately discontinue
                        using the Site and the Services.
                    </SubText>
                    <SubHead>Indemnification</SubHead>
                    <SubText margin="16px">
                        You agree to indemnify, defend, and hold Authenticating.com and its
                        officers, directors, employees, affiliates, agents, licensors, and suppliers
                        harmless from and against all claims, losses, expenses, damages, and costs,
                        including reasonable attorneys' fees, resulting from any violation of these
                        Terms by you.
                    </SubText>
                    <SubHead>Governing Law and Jurisdiction</SubHead>
                    <SubText margin="16px">
                        These Terms are governed by and construed in accordance with the laws of the
                        State of California, and any action arising out of or relating to these
                        terms shall be filed only in state or federal courts located in Los Angeles,
                        California, and you hereby consent and submit to the personal jurisdiction
                        of such courts for the purpose of litigating any such action.
                    </SubText>
                    <SubHead>Modification of Terms</SubHead>
                    <SubText margin="16px">
                        From time to time, and in our sole discretion, we may revise these Terms. If
                        the revision, in our sole discretion, is material we will notify you via an
                        email sent to the email address associated with your account, or by means of
                        a notice posted on this Site. By continuing to access or use the Site after
                        those revisions become effective, you agree to be bound by the revised
                        Terms.
                    </SubText>
                    <SubHead>Severability of Provisions</SubHead>
                    <SubText margin="16px">
                        If any provision of these Terms is deemed unlawful, void, or unenforceable,
                        then that provision shall be deemed severable from the remaining provisions
                        and shall not affect their validity and enforceability.
                    </SubText>
                    <SubHead>Contact Information</SubHead>
                    <SubText margin="16px">
                        If you have any questions about these Terms, or about the content,
                        information, or services on this website, you may contact us using any of
                        the methods described on our <a href="/#contact">Contact Us</a> page of the
                        Site.
                    </SubText>
                </SectionBody>
            </Container>
        </RootLayout>
    );
}
